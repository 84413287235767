// Import Packages
import * as React from "react"

// Import Templates
import { PageTitle, Header, Footer } from "../templates/Components"

// Import Assets
import "../css/style.css"

export default function ContactPage ({ location }) {
  return (
    <div>
      <PageTitle pageTitle="Contact | "/>
      <Header/>
      <div id="contents">
        <div className="clearfix">
          <div className="sidebar">
            <div>
              <h2>Contact Info</h2>
              <ul className="contact">
                <li>
                  <p>
                    <em>The Law Office of<br/>Dominic Rizzi PLLC</em>
                    <br/>
                    <span className="home"></span> 100 N Stone #601, Tucson, AZ, 85701
                  </p>
                </li>
                <li>
                  <p className="phone">
                    Phone: +1 520-591-7919 
                  </p>
                </li>
                {/* <!-- <li>
                  <p className="fax">
                    Fax: (+20) 000 222 988
                  </p>
                </li> --> */}
                <li>
                  <p className="mail">
                    Email: drizzi@rizzi-law.com
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="main">
            <h1>Contact</h1>
            <h2>Send Us a Quick Message</h2>
            <p>
              Send an email with the form below or call our office to schedule a consultation.
            </p>
            <div className="warning">
              <p>
                <strong>
                  Please do <em>NOT</em> send any sensitive, personal or confidential information regarding matters through this contact form. Anything submitted regarding matters is acknowledged as not sensitive, personal or confidential. The contact form was not established to solicit or receive legal inquiries. The transmission of such a message through this connection will not, and should not be construed to, create an attorney-client relationship between the Law Office of Dominic Rizzi and you or anyone else.
                </strong>
              </p>
            </div>
            <form action="mailto:drizzi@rizzi-law.com" method="get" className="message" id="email-form">
              <input type="submit" value="Send Message"/>
            </form>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}